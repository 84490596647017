import React, { useContext } from "react"
import { AppContext } from "../../contexts/AppContext"
import { QUERY_ALL_STATIONS } from "../../queries/page/station"
import {
  QUERY_NEXT_EPISODE_BY_DATE,
  QUERY_NEXT_EPISODE,
} from "../../queries/page/show"
import StyledImage from "../StyledImage"
import styled from "styled-components"
import { MEDIA_TYPE_AUDIO } from "../../constants/props"
import { getConfigValue, DATA_TYPE_STATION } from "../../constants/props"
import Carousel from "../SliderContainer/carousel"
import * as St from "../Styled"
import ReadMorePara from "../ReadMorePara"
import { convertToSlug, stationDetailsURL } from "../../constants/props"
import LikeAndShare from "../LikeAndShare"
import useRecordUserData from "../../hooks/useRecordUserData"
import Query from "../Query"
import momentTZ from "moment-timezone"
import auth from "../../auth/auth"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"
import { Link } from "gatsby"

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  position: relative;
`
const LinkStationDetailsPage = styled(Link)`
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    cursor: pointer;
    color: #fff;
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale}) translateX(45px);
      transition: all ${LANDSCAPE.linkHover.transition}s;
      color: ${LANDSCAPE.linkHover.color};
    }
  }
`
const DisableOverlay = styled.div`
  position: absolute;
  background: #ffffffa1;
  z-index: 9998;
  width: 100%;
  height: 100%;
  & span {
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: #454343c7;
    text-align: center;
    font-weight: 400;
    width: 100%;
    letter-spacing: 1.5px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    border-radius: ${MOBILE.stations.grid.image.borderRadius}px;
    & span {
      font-size: 12px;
    }
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    border-radius: ${LANDSCAPE.stations.grid.image.borderRadius}px;
    & span {
      font-size: 12px;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    border-radius: ${TAB.stations.grid.image.borderRadius}px;
    & span {
      font-size: 16px;
    }
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    border-radius: ${MEDIUM.stations.grid.image.borderRadius}px;
    & span {
      font-size: 20px;
    }
  }
  @media (min-width: ${LARGE.minWidth}px) {
    border-radius: ${LARGE.stations.grid.image.borderRadius}px;
  }
`

const basePrefix = `${process.env.STRAPI_SERVER_URL}`
const baseAmazonPrefix = `${process.env.AMAZON_BASE_PREFIX}`

const FeaturedStationContent = ({ data, device, nextEpisode }) => {
  const { updatePlayer, selectedCountry, userState, ctxPlayer } = useContext(
    AppContext
  )
  const {
    id,
    title,
    streamuri,
    subtitle,
    info,
    premium,
    image,
    available,
    slug,
  } = data
  console.log("featured stations slug is ", slug)
  const recordUserData = useRecordUserData({
    user: userState ? userState.id : null,
    id: id,
    type: DATA_TYPE_STATION,
  })

  let stationImage =
    image && image.url
      ? image.url.startsWith("http")
        ? image.url
        : basePrefix + image.url
      : ""

  const stationNameSlug = data && data.title ? convertToSlug(data.title) : "all"
  const stationID = data && data.id ? data.id : "all"

  const countryNameSlug =
    selectedCountry && selectedCountry.name
      ? convertToSlug(selectedCountry.name)
      : "all"

  const isStationPlaying =
    ctxPlayer.id === id &&
    ctxPlayer.dataType === DATA_TYPE_STATION &&
    ctxPlayer.playing
      ? true
      : false

  if (!stationImage || stationImage.trim().length === 0) {
    // show a default station image
    stationImage = baseAmazonPrefix + "/static/default-station-image.jpg"
  }

  return (
    <St.StationFeaturedWrapper>
      <St.StationFeaturedImageWrapper>
        {!available && (
          <DisableOverlay>
            <span>Currently Offline</span>
          </DisableOverlay>
        )}
        <StyledImage
          image={stationImage}
          width={"100%"}
          height={"100%"}
          premium={premium}
          hover={true}
          shaded={true}
          playing={isStationPlaying}
          imageAlt={title}
          borderRadius={
            getConfigValue(device, "box.station.featured.image.borderRadius") +
            "px"
          }
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()

            if (!isStationPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_AUDIO,
                id,
                title,
                streamuri: streamuri,
                preview: image,
                subtitle,
                data: info,
                live: true,
                dataType: DATA_TYPE_STATION,
                playing: true,
              })

              if (userState && userState.isLoggedIn) {
                recordUserData()
              }
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }
          }}
        />
      </St.StationFeaturedImageWrapper>

      <St.StationFeaturedRightTextSection>
        <LinkStationDetailsPage to={`/radio/${slug}`}>
          <St.StationFeaturedTitle>
            {data.title ? data.title : ""}
          </St.StationFeaturedTitle>
        </LinkStationDetailsPage>

        {nextEpisode && nextEpisode.show && nextEpisode.show.presenter ? (
          <St.StationFeaturedSingleLineText>
            <div>Presenter:</div>
            <span>{nextEpisode.show.presenter.title}</span>
          </St.StationFeaturedSingleLineText>
        ) : null}
        {nextEpisode ? (
          <St.StationFeaturedSingleLineText>
            <div>Next Show:</div>
            <span>{nextEpisode.from}</span>
          </St.StationFeaturedSingleLineText>
        ) : null}

        <St.StationFeaturedMultiLineText>
          <div>About:</div>
          <span>
            <ReadMorePara
              string={data.about}
              offset={getConfigValue(device, "box.station.featured.textOffset")}
            />
          </span>
        </St.StationFeaturedMultiLineText>

        <St.FeaturedSocialIconSection>
          <LikeShare>
            <LikeAndShare
              shareID={"featuredstation_" + stationID}
              id={stationID}
              likeType={DATA_TYPE_STATION}
              shareURL={stationDetailsURL(slug)}
              copyEmbedCode={true}
            />
          </LikeShare>
        </St.FeaturedSocialIconSection>
      </St.StationFeaturedRightTextSection>
    </St.StationFeaturedWrapper>
  )
}

const FeaturedStationNextShow = ({ data, device, referenceDate }) => {
  return referenceDate ? (
    <Query
      query={QUERY_NEXT_EPISODE_BY_DATE}
      variables={{
        stationID: data.id,
        referenceDate: referenceDate,
      }}
      fetchPolicy="network-only"
    >
      {({ data: { episodes } }) => {
        return episodes && episodes.length ? (
          <FeaturedStationContent
            data={data}
            device={device}
            nextEpisode={episodes && episodes.length > 0 ? episodes[0] : null}
          />
        ) : (
          <Query
            query={QUERY_NEXT_EPISODE}
            variables={{
              stationID: data.id,
            }}
            fetchPolicy="network-only"
          >
            {({ data: { episodes } }) => {
              return episodes && episodes.length > 0 ? (
                <FeaturedStationContent
                  data={data}
                  device={device}
                  nextEpisode={
                    episodes && episodes.length > 0 ? episodes[0] : null
                  }
                />
              ) : (
                <FeaturedStationContent data={data} device={device} />
              )
            }}
          </Query>
        )
      }}
    </Query>
  ) : (
    <FeaturedStationContent data={data} device={device} />
  )
}

const FeaturedStations = ({ stations }) => {
  // stations should have at least one entry as per logic in parent so we will find one featured station is stations
  let { selectedCountry, device } = useContext(AppContext)

  let settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    lazyLoad: false,
    arrows: false,
    autoplay: true,
    dots: true,
    autoplaySpeed: 2000,
    dotsClass: "carousel-dots",
  }

  return (
    <St.FeaturedBox>
      <St.FeaturedBoxContent>
        <St.FeaturedBoxHeading>
          FEATURED STATION: {selectedCountry ? selectedCountry.name : null}
        </St.FeaturedBoxHeading>
        <Carousel settings={settings}>
          {stations &&
            stations.map(station => {
              let referenceDate = null
              if (station.country && station.country.Timezone) {
                // Find out current day/time in station country
                let countryMoment = momentTZ().tz(station.country.Timezone)
                // Read comments added in showsform.js when episode is added for shows for reason of using march 2020 dates
                let utcMoment = momentTZ()
                  .utc()
                  .date(1) // 1st of march
                  .month(2) // march
                  .year(2020)
                  .day(countryMoment.day())
                  .hour(countryMoment.hour())
                  .minute(countryMoment.minute())
                  .second(0)
                  .millisecond(0)

                // this reference date would be passed to next component where it will be used to find next episode and hence next show
                referenceDate = utcMoment.toDate()
              }

              return (
                <FeaturedStationNextShow
                  key={station.id}
                  data={station}
                  device={device}
                  referenceDate={referenceDate}
                />
              )
            })}
        </Carousel>
      </St.FeaturedBoxContent>
    </St.FeaturedBox>
  )
}

const FeaturedStationsParent = ({ selectedCountry }) => {
  let criteria = { featured: true, active: true }

  if (selectedCountry && selectedCountry.id && selectedCountry.name !== "All") {
    criteria["country_in"] = selectedCountry.id
  }

  return (
    <Query
      variables={{
        where: criteria,
      }}
      query={QUERY_ALL_STATIONS}
      fetchPolicy="network-only"
    >
      {({ data: { stations } }) => {
        return (
          stations &&
          stations.length > 0 && <FeaturedStations stations={stations} />
        )
      }}
    </Query>
  )
}

export default FeaturedStationsParent
