import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import * as St from "../../Styled"
import LikeAndShare from "../../LikeAndShare"

import useRecordUserData from "../../../hooks/useRecordUserData"

import StyledImage from "../../StyledImage"
import { MOBILE, LANDSCAPE, TAB, MEDIUM, LARGE } from "../../../constants/breakpoints"
import {
  MEDIA_TYPE_AUDIO,
  getConfigValue,
  DATA_TYPE_STATION,
} from "../../../constants/props"

import { AppContext } from "../../../contexts/AppContext"

const StationTitle = styled(Link)`
@media (min-width: ${LANDSCAPE.minWidth}px) {
  &:hover {
    text-decoration: none;
    transform: scale(${LANDSCAPE.linkHover.scale}) translateX(${LANDSCAPE.linkHover.translateX}px);
    transition: all ${LANDSCAPE.linkHover.transition}s;
    & h3 {
      color: ${LANDSCAPE.linkHover.color};
    }
  }
}
`
const LikeShare = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  flex: 1;
`

const DisableOverlay = styled.div`
  position: absolute;
  background: #ffffffa1;
  z-index: 9998;
  & span {
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: #454343c7;
    text-align: center;
    font-weight: 400;
    width: 100%;
    letter-spacing: 1.5px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.stations.grid.image.widthHeight}vw;
    height: ${MOBILE.stations.grid.image.widthHeight}vw;
    border-radius: ${MOBILE.stations.grid.image.borderRadius}px;
    & span {
      font-size: 12px;
    }
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.stations.grid.image.widthHeight}vw;
    height: ${LANDSCAPE.stations.grid.image.widthHeight}vw;
    border-radius: ${LANDSCAPE.stations.grid.image.borderRadius}px;
    & span {
      font-size: 12px;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.stations.grid.image.widthHeight}px;
    height: ${TAB.stations.grid.image.widthHeight}px;
    border-radius: ${TAB.stations.grid.image.borderRadius}px;
    & span {
      font-size: 16px;
    }
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.stations.grid.image.widthHeight}px;
    height: ${MEDIUM.stations.grid.image.widthHeight}px;
    border-radius: ${MEDIUM.stations.grid.image.borderRadius}px;
    & span {
      font-size: 20px;
    }
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.stations.grid.image.widthHeight}px;
    height: ${LARGE.stations.grid.image.widthHeight}px;
    border-radius: ${LARGE.stations.grid.image.borderRadius}px;
  }
`

const baseAmazonPrefix = `${process.env.AMAZON_BASE_PREFIX}`

const ItemCards = ({ playlist = [], data, idx }) => {
  const {
    updatePlayer,
    userState,
    device,
    ctxPlayer,
  } = useContext(AppContext)

  const { id, title, streamuri, subtitle, info, isPremium, image, available, slug } = data
  const recordUserData = useRecordUserData({
    user: userState ? userState.id : null,
    id: data ? data.id : null,
    type: DATA_TYPE_STATION,
  })

  // As on large desktop we have a break point of 1000px and we want 3 thumbnails to come
  // we will keep width so that it does not allow 4 thumbnails that way layout would
  // only add 3 thumbnails and wrap other to fourth

  const stationID = data && data.id ? data.id : "all"

  const isStationPlaying =
    ctxPlayer.id === id &&
    ctxPlayer.dataType === DATA_TYPE_STATION &&
    ctxPlayer.playing
      ? true
      : false
  var stationImage = image

  if(!stationImage || stationImage.trim().length === 0){
    // show a default station image
    stationImage = baseAmazonPrefix + "/static/default-station-image.jpg"
  }

  return (
    <St.StationGridWrapper>
      {!available && <DisableOverlay>
          <span>Currently Offline</span>
        </DisableOverlay>}
      <St.StationGridImageWrapper>
        <StyledImage
          image={stationImage}
          width={"100%"}
          height={"100%"}
          premium={isPremium}
          hover={true}
          playing={isStationPlaying}
          imageAlt={title}
          borderRadius={
            getConfigValue(device, "box.podcasts.image.borderRadius") + "px"
          }
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()

            if (!isStationPlaying) {
              console.log("[PLAYER] updatePlayer () called")
              updatePlayer({
                type: MEDIA_TYPE_AUDIO,
                id,
                title,
                streamuri: streamuri,
                preview: image,
                subtitle,
                playlist,
                data: info,
                live: true,
                dataType: DATA_TYPE_STATION,
                playing: true,
              })

              if (userState && userState.isLoggedIn) {
                recordUserData()
              }
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }
          }}
        />
      </St.StationGridImageWrapper>

      <St.StationGridBottomTextSection>
        <StationTitle
          to={`/radio/${slug}`}
        >
            <St.StationGridTitle title={title}>{title}</St.StationGridTitle>
        </StationTitle>

        {data.presenters &&
        data.presenters.length &&
        data.presenters[0].title ? (
          <St.StationGridSingleLineText>
            <span>Presenter: {data.presenters[0].title}</span>
          </St.StationGridSingleLineText>
        ) : null}
        <St.StationGridRow>
          <St.StationGridSingleLineText style={{ flexShrink: 1 }}>
            <span>{data.country && data.country.title}</span>
          </St.StationGridSingleLineText>
          <LikeShare>
            <LikeAndShare
              shareURL={`/radio/${slug}`}
              id={stationID}
              likeType={DATA_TYPE_STATION}
              shareID={"allstation_" + stationID}
              position={["top", "left"]}
              copyEmbedCode={true}
            />
          </LikeShare>
        </St.StationGridRow>
      </St.StationGridBottomTextSection>
    </St.StationGridWrapper>
  )
}

export default ItemCards
