import React, { useContext } from "react"
import { AppContext } from "../../contexts/AppContext"
import * as St from "../Styled"
import FeaturedStations from "./FeaturedStations"
import AllStations from "./AllStations"
import SEO from "../Seo"

const ContentCountryStations = ({
  seoTitle,
  seoDescription,
  seoImage,
  seoImageWidth,
  seoImageHeight,
}) => {
  let { selectedCountry, seoStations } = useContext(AppContext)
  return (
    <St.ContentParent>
      <SEO
        {...{
          seoTitle,
          seoDescription,
          seoImage,
          seoImageWidth,
          seoImageHeight,
        }}
      />
      <St.AdWrapper>
        <St.LeftAdSection />
        <St.LeftAd2Section />
      </St.AdWrapper>
      <St.ContentWrapper>
        {selectedCountry && selectedCountry.id && (
          <FeaturedStations selectedCountry={selectedCountry} />
        )}
        <St.CenterContentSection>
          {selectedCountry && selectedCountry.id && (
            <AllStations
              selectedCountry={selectedCountry}
              seoStations={seoStations}
            />
          )}
        </St.CenterContentSection>
        <St.BottomAdSection />
      </St.ContentWrapper>
      <St.AdWrapper>
        <St.RightAdSection />
        <St.RightAd2Section />
      </St.AdWrapper>
    </St.ContentParent>
  )
}

export default ContentCountryStations
