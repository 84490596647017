import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { QUERY_ALL_STATIONS } from "../../queries/page/station"
import * as St from "../Styled"
import ItemCards from "./ItemCards"

const basePrefix = `${process.env.STRAPI_SERVER_URL}`

const StationCards = ({ stations }) => {
  return stations.map((item, i) => (
    <ItemCards
      key={item.id}
      idx={i}
      data={{
        ...item,
        image:
          item.image && item.image.url
            ? item.image.url.startsWith("http")
              ? item.image.url
              : basePrefix + item.image.url
            : "",
        isPremium: item.premium,
        subtitle: item.country ? item.country.title : "",
        likeShare: false,
        liked: false,
      }}
    />
  ))
}

const AllStations = ({ selectedCountry, seoStations }) => {
  let criteria = { active: true }

  let [sortCriteria, setSortCriteria] = useState("title:asc")
  const onSortChange = event => {
    // FIND COUNTRY IN COUNTRIES ARRAY WITH SELECTED ID AND EXPAND IT TO CREATE NEW OBJECT AND SET AS NEW SELECTED COUNTRY
    setSortCriteria(event.target.value)
  }

  if (selectedCountry && selectedCountry.id && selectedCountry.name !== "All") {
    criteria["country_in"] = selectedCountry.id
  }

  const { data, loading } = useQuery(QUERY_ALL_STATIONS, {
    variables: {
      where: criteria,
      order: sortCriteria,
    },
    fetchPolicy: "network-only",
  })

  const stations =
    !loading && data && data.stations && data.stations.length
      ? data.stations
      : seoStations && seoStations.length
      ? seoStations
      : undefined


  return stations ? (
    <St.AllCardsSection>
      <St.AllCardsTopSection>
        <St.AllCardsHeading>
          RADIO STATIONS: {selectedCountry ? selectedCountry.name : null}
        </St.AllCardsHeading>
        <St.SortSpan>
          SORT :
          <St.SortSelect defaultValue={sortCriteria} onChange={onSortChange}>
            <St.SortOption value="title:asc">A-Z</St.SortOption>
            <St.SortOption value="title:desc">Z-A</St.SortOption>
          </St.SortSelect>
        </St.SortSpan>
      </St.AllCardsTopSection>
      <St.StationAllWrapper>
        <StationCards stations={stations} />
      </St.StationAllWrapper>
    </St.AllCardsSection>
  ) : !loading ? (
    <St.NoDataMessage>
      No Stations for this country yet, please encourage local stations to sign
      up.
    </St.NoDataMessage>
  ) : null
}

export default AllStations
